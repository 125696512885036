import ALL_INVOICES from '@/graphql/invoices/query/getAllInvoicesAutocomplete.gql'

const invoices = ref<{label: string; value: string}[]>([])
const key = ref(0)
const firstTime = ref(true)

export async function invoicesAutocomplete(params: any) {
  const apolloQuery = ALL_INVOICES
  const variables = {
    search: params.search ? params.search : null,
    orderBy: [
      {
        column: 'NUMBER',
        order: params.sortDesc ? 'DESC' : 'ASC'
      }
    ],
    first: 10,
    page: 1
  }

  if (!variables.search) {
    delete variables.search
  }

  const result = query(apolloQuery, variables, { fetchPolicy: 'network-only' })

  return result
}

export async function searchInvoicesCb(queryString: any, cb: (arg0: any) => void) {
  const { $showError, $sentry } = useNuxtApp()
  try {
    const results = await invoicesAutocomplete({ search: queryString })
    cb(results?.getAllInvoices?.data ?? [])
  } catch (err) {
    $showError(err)
    $sentry(err, 'searchInvoicesCb')
  }
}

export function getSearchInvoices(entityInvoice: any, entityInvoices: any) {
  if (firstTime.value === true) {
    firstTime.value = false
    searchInvoices('', entityInvoice, entityInvoices)
  }

  return invoices?.value ?? []
}

export async function searchInvoices(queryString: any, entityInvoice: any, entityInvoices: any[], refresh = false) {
  const { $showError, $sentry } = useNuxtApp()
  try {
    const results = await invoicesAutocomplete({ search: queryString })
    const remoteInvoices = results?.getAllInvoices?.data?.map((i: any) => {
      return {
        label: i.number,
        value: i.uuid
      }
    }) ?? []
    const invoicesToAdd: { label: any; value: any }[] = []
    entityInvoices?.forEach((j: any) => {
      if (!remoteInvoices.find((k: any) => k.value === j.uuid)) {
        invoicesToAdd.push({
          label: j.number,
          value: j.uuid
        })
      }
    })
    if (entityInvoice && !remoteInvoices.find((k: any) => k.value === entityInvoice.uuid)) {
      invoicesToAdd.push({
        label: entityInvoice.number,
        value: entityInvoice.uuid
      })
    }
    if (refresh) {
      key.value = 0
    }
    if (key.value === 0) {
      key.value = setCustomKeyVariable()
    }
    invoices.value = [...remoteInvoices, ...invoicesToAdd]
    firstTime.value = false

    if (entityInvoice) {
      getSearchInvoices(entityInvoice, entityInvoices)
    }
  } catch (err) {
    $showError(err)
    $sentry(err, 'searchInvoices')
  }
}

export function getInvoiceKey () {
  return key.value
}

export function initialInvoices () {
  firstTime.value = true
  invoices.value = []
  key.value = 0
}